import React from "react";
import { Helmet } from "react-helmet-async";

function KnowMore() {
  return (
    <>
      <Helmet>
        <title>और जानें | MeraPashu360</title>
        <meta name="description" content="MeraPashu360." />
      </Helmet>
      <div className="terms--root">
        <div className="policy-section">
          <h2>मेरापशु360 के बारे में:</h2>
          <p>
            मेरापशु360 एक कृषि-प्रौद्योगिकी कंपनी है जो पशुपालकों को उनके पशुओं
            के स्वास्थ्य और कल्याण के लिए आवश्यक उत्पाद और सेवाएं प्रदान करती
            है। कंपनी की स्थापना 2021 में हुई थी और इसका मुख्यालय गुरुग्राम में
            स्थित है।
          </p>
          <p>
            पशुपालकों को समृद्ध एवं डेयरी सेक्टर को विकसित करने की दिशा में
            मेरापशु360 की तैयार की गई एक अनुभवी टीम है, जिसने डेयरी किसानों के
            जीवन को बेहतर बनाने के लिए एक प्रौद्योगिकी-सह-भौतिक मंच तैयार किया
            है, मेरापशु360 हर डेयरी किसान को अच्छी गुणवत्ता वाले पशु  एवं
            क्वालिटी पशु आहार के अनेक विकल्प घर बैठे ऐप से मंगवाने की सुविधा
            देते हैं। मेरापशु360 भारत के कई राज्यों और जिलों में अपने उत्पादों
            और सेवाओं का विस्तार कर रही हैI  कंपनी का एक मात्र प्राप्ति लक्ष्य-
            ‘स्वस्थ पशु, समृद्ध किसान है।
          </p>
          <p>
            वेबसाइट:{" "}
            <a href="https://merapashu360.com/">https://merapashu360.com/</a>
          </p>
          <p>हेल्पलाइन नंबर: 9971182446</p>

          <h2>रेफरल प्रोग्राम:</h2>
          <p>
            डाउनलोड करवाएं छूट पाएं मेरापशु360 ऐप से दोस्तों को रेफरल लिंक शेयर
            करने पर आपको मिलेंगे 250 पॉइंट्स। 50 पॉइंट्स दोस्त के साइन-अप पर,
            200 पॉइंट्स दोस्त के पहले ऑर्डर डिलीवरी के बाद जिसका इस्तेमाल ऑर्डर
            में करके आपको पशु आहार पर छूट मिलेगी। * 1 रिवार्ड्स पॉइंट्स = ₹1 की
            छूट प्रति किलो पर
          </p>

          <h2>आप मेरापशु360 से क्या खरीद सकते हैं?</h2>
          <p>
            ऐप पर पशु के लिए किफायती दामों में पोषक तत्वों से भरपूर प्रीमियम
            क्वालिटी पशु आहार जैसे- खल, गोली खल, पारंपरिक पशु आहार, साइलेज,
            मिनेरल मिश्रण के 20 से ज्यादा विकल्प एवं कॉम्बो पैक आपके लिए उपलब्ध
            है। साथ ही ऐप पर आप घर बैठे उत्तम क्वालिटी की गाय/ मुर्रा नस्ल की
            भैंस के कई विकल्प उनकी ब्यात, तैयार दूध और कीमत के अनुसार देख सकते
            हैं और चाहें तो फार्म विजिट की बुकिंग करके आप भैंस की लाइव मिल्किंग
            देख सकते हैं।
          </p>

          <h2>मेरापशु360 से क्यों खरीदें?</h2>
          <p>
            लैब द्वारा प्रमाणित गुणवत्ता वाले पशु आहार: ऐप पर उपलब्ध सभी
            प्रीमियम गुणवत्ता वाले ताजे पशु आहार को मेरापशु360 के अनुभवी पशु
            पोषण विशेषज्ञों की निगरानी में तैयार करने के बाद सीधे कारखाने से
            आपके घर पर पहुंचाया जाता है। 75+ पैमानों पर पशु की जांच: मेरापशु360
            के अनुभवी पशु चिकित्सक द्वारा टेक्नोलॉजी की मदद से 75+ पैमानों पर
            पशु की क्वालिटी को चेक किया जाता है, इसमें गाय/भैंस के शरीर का आकार,
            उसके सींग, चमड़ी, आंखें, नाक, थानों की बनावट के आकार की जांच की जाती
            है। पशु के शरीर के पिछले अंगों की तस्वीर से उसको एक अंक दिया जाता
            है, जिसे 'बॉडी कंडीशन स्कोर' (BCS) कहा जाता है। बीसीएस के आधार पर
            पशु के स्वास्थ्य के बारे में पता लगता है और उसका उचित दाम निर्धारित
            किया जाता हैI इन प्रत्येक प्रतिक्रिया में पास होने के बाद पशु को हब
            पर लाया जाता है।
          </p>

          <h2>सुविधाएं:</h2>
          <ul>
            <li>
              फास्ट एंड फ्री होम डिलीवरी: पशु आहार का 1 कट्टा भी पहुंचेगा आपके
              घर 24 घंटे मै।
            </li>
            <li>
              कैश ऑन डिलीवरी: बिना किसी परेशानी, ऑर्डर घर पर डिलीवर होने के बाद
              नकद देकर करें भुगतान।
            </li>
            <li>
              UPI भुगतान पर छूट: ऐप से पशु आहार के ऑनलाइन भुगतान पर ₹100 की छूट
              पाएं।
            </li>
            <li>
              पशु आहार के अनेक विकल्पों वाला एक ऐप: ऑनलाइन खल, गोली खल, पारंपरिक
              पशु आहार, साइलेज, मिनेरल मिश्रण की श्रेणीयां  एवं लाभ आप ऐप पर देख
              सकते ह। 
            </li>
          </ul>

          <h2>
            <a
              href="https://merapashu360.com/feed/khal"
              className="clicked-text"
            >
              खल:
            </a>
          </h2>
          <ul>
            <li>
              <a
                href="https://merapashu360.com/feed/fat-15000-49Kg-premium-binola-khal"
                className="sub-text"
              >
                {" "}
                फैट 15000 कच्ची प्रीमियम बिनोला खल:{" "}
              </a>
              फैट 15000 कच्ची प्रीमियम बिनोला खल: यह पशु के लिए फैट एवं प्रोटीन
              का उत्तम स्रोत हैI साथ ही बाई-पास प्रोटीन का भीI फैट- SNF की
              मात्रा को बढ़ाता हैI पशु को स्वस्थ रखने में सहायक ह।
            </li>
            <li>
              <a
                href="https://merapashu360.com/feed/sarso-khal"
                className="sub-text"
              >
                {" "}
                सरसों की खल (कच्ची घानी):{" "}
              </a>
              हाई प्रोटीन का स्रोत हैI सरसों की कच्ची खल को 2 से 4 घंटे भिगोकर
              पशु को खिलाएंI इसमें 35% प्रोटीन की और 7% तेल की मात्रा रहती हैं।
            </li>
          </ul>

          <h2>
            <a
              href="https://merapashu360.com/feed/goli_khal"
              className="clicked-text"
            >
              {" "}
              गोली खल (संतुलित आहार):
            </a>
          </h2>
          <ul>
            <li>
              <a
                href="https://merapashu360.com/feed/santulit-8000-pellet"
                className="sub-text"
              >
                {" "}
                संतुलित 8000:{" "}
              </a>
              यह पशु के लिए संतुलित आहार हैI इससे पशु को भारी मात्रा में प्रोटीन
              और फैट मिलता है, जो दुधारू पशु के स्वास्थ्य और अधिक दूध उत्पादन के
              लिए जरूरी होता है।
            </li>
            <li>
              <a
                href="https://merapashu360.com/feed/santulit-11000-pellet"
                className="sub-text"
              >
                {" "}
                संतुलित 11000 (एनर्जी पैलेट):{" "}
              </a>
              ऊर्जा से भरपूर संतुलित 11000 पशुओं की ऊर्जा को बढ़ाता है। इसमें
              2700 किलो कैलोरी और 70% टी.डी.एन की मात्रा होती है। 50 किलो के
              कट्टे में आने वाला इस न्यू संतुलित 11000 में बिनोला खल, सरसों खल,
              मक्का, पौष्टिक पदार्थ, टोक्सिन बाइंडर, मिनरल मिक्सचर, चोकर शामिल
              होते हैं।
            </li>
            <li>
              <a
                href="https://merapashu360.com/feed/santulit-15000-pellet"
                className="sub-text"
              >
                {" "}
                संतुलित 15000 (हाई एनर्जी पैलेट):{" "}
              </a>
              इसमें है कई बेहतरीन पोषक तत्व जिससे आपके पशुओं को मिलेगी हाई
              एनर्जी यानि भरपूर ऊर्जा इसमें 2900 किलो कैलोरी और 75% टी.डी.एन.
              मात्रा पाई जाती है। इससे पशु के दूध उत्पादन में वृद्धि होगी। उनका
              वजन बढ़ेगा प्रजनन और बेहतर एवं आसान बनेगा।
            </li>
          </ul>

          <h2>
            <a
              href="https://merapashu360.com/feed/traditional_khal"
              className="clicked-text"
            >
              {" "}
              पारंपरिक पशु आहार (ट्रेडिशनल फीड):
            </a>
          </h2>
          <ul>
            <li>
              <a
                href="https://merapashu360.com/feed/premium-chana-churi"
                className="sub-text"
              >
                {" "}
                प्रीमियम चना चूरी:{" "}
              </a>
              चने की चूरी पशुओं के लिए एक बेहतरीन आहार है। इसमें प्रोटीन,
              कार्बोहाइड्रेट, फाइबर,और मिनरल्स भरपूर मात्रा में पाए जाते हैं।
              चने की चूरी पशुओं का दूध बढ़ाने एवं स्वस्थ रखने में मदद करती है।{" "}
            </li>
            <li>
              <a
                href="https://merapashu360.com/feed/AF-Soya-Chilka-35KG"
                className="sub-text"
              >
                सोया छिलका:{" "}
              </a>
              इसमें प्रोटीन की मात्रा अच्छी होती है, जो दुधारू पशु के लिए आवश्यक
              हैI यह गर्भवती पशु के लिए बहुत फायदेमंद होता ह।{" "}
            </li>
            <li>
              <a
                href="https://merapashu360.com/feed/chana-chilka-20kg"
                className="sub-text"
              >
                {" "}
                चना छिलका:{" "}
              </a>
              यह दुधारू पशु को अच्छी मात्रा में ऊर्जा एवं प्रोटीन प्रदान करता
              हैI साथ ही दूध उत्पादन की क्षमता को बढ़ाता है।
            </li>
            <li>
              <a
                href="https://merapashu360.com/feed/merapashu-choker"
                className="sub-text"
              >
                {" "}
                बारीक चोकर:{" "}
              </a>
              इसमें फाइबर एवं फास्फोरस की अधिक मात्रा होती हैI जिससे पशु का पाचन
              बढ़िया और स्वस्थ रहता हैI चोकर में 65 से 70% तक पोषक तत्व शामिल
              होते हैं।
            </li>
          </ul>

          <h2>
            <a
              href="https://merapashu360.com/feed/silage"
              className="clicked-text"
            >
              प्रीमियम क्वालिटी साइलेज (हरा चारा):
            </a>
          </h2>
          <p>
            साइलेज पशु की पाचन क्रिया को आसान बनाता हैI यह पशुओं के लिए
            स्वादिष्ट एवं विटामिन मिनरल से भरपूर होता हैI यह किफायती दाम में हरे
            चारे का उत्तम विकल्प है, जो पूरे साल भर चलता है साथ ही हरे चारे की
            कमी को पूरा करता है।{" "}
          </p>

          <h2>
            <a
              href="https://merapashu360.com/feed/mineral_mix"
              className="clicked-text"
            >
              {" "}
              प्रीमियम क्वालिटी कैल्सी 15000 (कैल्शियम सप्लिमेंट):
            </a>
          </h2>
          <p>
            कैल्सी पशु के दूध और प्रजनन क्षमता को बढ़ाने वाला लिक्विड सप्लिमेंट
            हैI इसे कैल्शियम एवं विभिन्न विटामिन्स, फास्फोरस को सही अनुपात में
            मिलाकर बनाया गया हैI यह पशु में कैल्शियम की पूर्ति, दूध में वृद्धि
            एवं रोग प्रतिरोधक क्षमता को बढ़ाने में सहायक है।
          </p>

          <h2>
            <a
              href="https://merapashu360.com/feed/combo_offer"
              className="clicked-text"
            >
              {" "}
              कॉम्बो ऑफर्स:
            </a>
          </h2>
          <p>ऐप के माध्यम से पशु आहार कॉम्बो की भारी छूटें उपलब्ध हैं।</p>
        </div>
      </div>
    </>
  );
}

export default KnowMore;
