import { Routes, Route, Outlet, useLocation, Navigate, useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Feed from './pages/Feed/Feed';
import Footer from './components/Footer';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import ContactUs from './pages/ContactUs';
import AboutUsPage from './pages/AboutUsPage';
import Terms18Dec from './pages/Terms18Dec';
import BuyCattle from './pages/Cattle/BuyCattle';
import FeedDetail from './pages/Feed/FeedDetail';
import TermsReferral from './pages/TermsReferral';
import SYCForm from './pages/SYCForm';
import NotFound from './pages/NotFound';
import Home from './pages/Home/Home';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import translations from './Translations';
// import Career from './pages/Career';
import Cancellation from './pages/Cancellation';
import FAQ from './pages/Home/sections/FAQ';
import BlogPage from './pages/blogPost';
import FullBlogPage from './pages/fullBlogPage';
import PartnerShipPage from './pages/partnershipPage';

import FeedMineralMixture from './pages/Feed/FeedMineralMixture';
import FeedComboOffer from './pages/Feed/FeedCombo';
import FeedKhal from './pages/Feed/FeedKhal';
import FeedSilage from './pages/Feed/FeedSilage';
import FeedGoliKhal from './pages/Feed/FeedGoliKhal';
import FeedTraditionalFeed from './pages/Feed/FeedTraditionalFeed';
import CommercePolicy from './pages/CommercePolicy';
import KnowMore from './pages/KnowMore';


function RoutesByLanguage() {
  function Layout() {
    return (
      <>
        <Navbar />

        <Outlet />

        <Footer />
      </>
    );
  }

  type RedirectProp = {
    url: string;
  };

  function Redirect({ url }: RedirectProp) {
    window.location.href = url;
    return <></>;
  }
  function CattleFeedListing() {
    let query = useQuery();
    let feedCategory = query.get("feedCategory");

    switch (feedCategory) {
      case "Concentrate":
        return <FeedGoliKhal />;
      case "Khal":
        return <FeedKhal />;
      case "Feed Combo":
        return <FeedComboOffer />;
      case "Traditional Feed":
        return <FeedTraditionalFeed />;
      default:
        return <FeedKhal />;
    }
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
 
  function FeedWithDetail() {
    const query = useQuery();
    const sku = query.get("sku");

    if (sku) {
      return <FeedDetail />;
    } else {
      return <Feed />;
    }
  }


  let innerRoutes = (
    <>
      <Route path="" element={<Home />} />
      <Route path="feed" element={<FeedWithDetail />} />
      <Route path="home/cattle-feed-listing" element={<CattleFeedListing />} />
      <Route path="feed/combo_offer" element={<FeedComboOffer/>}/>
      <Route path="feed/khal" element={<FeedKhal/>}/>
      <Route path="feed/goli_khal" element={<FeedGoliKhal/>}/>
      <Route path="feed/silage" element={<FeedSilage/>}/>
      <Route path="feed/mineral_mix" element={<FeedMineralMixture/>}/>
      <Route path="feed/traditional_khal" element={<FeedTraditionalFeed/>}/>
      <Route path="feed/:slug" element={<FeedDetail />} />
      
      <Route path="buy-cattle" element={<BuyCattle />} />
      {/* <Route path="buy-cattle/:id" element={<CattleDetail />} /> */}
      <Route path="cattle/breed/murrah" element={<BuyCattle />} />
      <Route path="know-more" element={<KnowMore/>}/>
      <Route path="contact-us" element={<ContactUs />} />
      <Route path="about-us" element={<AboutUsPage />} />
      <Route path="career" element={<AboutUsPage />} />
      <Route path="faq" element={<FAQ />} />
      <Route path="sell-cattle" element={<SYCForm />} />
      <Route path="terms-of-service" element={<Terms />} />
      <Route path="cancellation-policy" element={<Cancellation />} />
      <Route path="commerce-policy" element={<CommercePolicy/>}/>
      <Route path="privacy-policies" element={<Privacy />} />
      <Route path="blog" element={<BlogPage />} />
      <Route path="blog/:id" element={<FullBlogPage />} />
      {/* <Route path="partnership-page" element={<PartnerShipPage />} /> */}
      <Route path="404" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
      {/* TnC Referral */}
      <Route path="referral-terms" element={<TermsReferral />} />
      {/* TnC Pratyogita 18 Dec  */}
      <Route path="free-pashu-aahar-18-dec-tnc" element={<Terms18Dec />} />
      {/* Temp SEO Redirect  */}
      <Route path="home" element={<Redirect url="/" />} />
      <Route path="pages/feed" element={<Redirect url="/feed" />} />
      <Route
        path="collections/our-products"
        element={<Redirect url="feed" />}
      />
      <Route path="home/cattle" element={<Redirect url="/buy-cattle" />} />
      <Route path="pages/about-us" element={<Redirect url="about-us" />} />{' '}
      <Route path="pages/contact" element={<Redirect url="/ontact-us" />} />
      <Route
        path="pages/terms-of-service"
        element={<Redirect url="terms-of-service" />}
      />
      <Route
        path="pages/cancellation-policy"
        element={<Redirect url="cancellation-policy" />}
      />
      <Route
        path="pages/privacy-policy"
        element={<Redirect url="/privacy-policies" />}
      />
      <Route
        path="collections/murrah-buffalo"
        element={<Redirect url="/cattle/breed/murrah" />}
      />
      <Route
        path="collections/cattles"
        element={<Redirect url="/buy-cattle" />}
      />
      <Route path="collections/5-gst" element={<Redirect url="/" />} />
      {[
        'products/silage',
        'collections/animal-feed',
        'products/chana-churi',
        'products/kansal-sarso-cake',
        'products/gyandhara-chelated-mineral-mixture',
        'products/gyandhara-milk-maxima-10-ltr',
        'products/gyandhara-bhains-special',
        'products/dairy-pro-10000-pellet',
        'products/shakti-bhog-pellets',
        'products/godrej-double-can-cotton-cake',
        'products/mustard-doc',
        'products/godrej-moo-magic',
        'products/goyal-soya-chilka',
        'collections/concentrate',
      ].map((item: string, index:number) => (
        <Route key={index} path={item} element={<Redirect url="/feed" />} />
      ))}
      {[
        'blogs/news',
        'collections/cows',
        'collections/doc',
        'collections/frontpage',
        'collections/10-15-kg',
        'pages/feed-advisory',
        'pages/veterinary-services',
        'products/artificial-insemination',
      ].map((item: string, index:number) => (
        <Route key={index} path={item} element={<Redirect url="/" />} />
      ))}
      <Route path="new-cattles" element={<Redirect url="/buy-cattle" />} />
      <Route
        path="products/gyandhara-hi-power"
        element={<Redirect url="/feed" />}
      />
      <Route
        path="products/gyandhara-gold-pro"
        element={<Redirect url="/feed" />}
      />
      <Route
        path="products/ras-amrit-bindolas-khal"
        element={<Redirect url="/feed" />}
      />
      <Route
        path="products/trishul-binola-khal"
        element={<Redirect url="/feed" />}
      />
      <Route
        path="products/barik-chota-choker"
        element={<Redirect url="/feed" />}
      />
      <Route
        path="products/shakti-bhog-chokar"
        element={<Redirect url="/feed" />}
      />
      <Route
        path="products/shakti-bhog-choker-barik-premium"
        element={<Redirect url="/feed" />}
      />
      <Route
        path="feed/cdaf37f4-c398-43e2-8d02-508ed5e79994"
        element={<Redirect url="/feed" />}
      />
      <Route
        path="products/shaktibhog-daliya-premium"
        element={<Redirect url="/feed" />}
      />
      <Route
        path="products/daliya"
        element={<Redirect url="/feed" />}
      />
      {[
        'products/buffalo-mp360-483',
        'products/buffalo-mp360-484',
        'products/buffalo_mp360-500',
        'products/buffalo-mp360-500',
        'products/buffalo-mp360-475',
        'products/buffalo-mp360-511',
        'products/buffalo-mp360-499',
        'collections/new-cattles',
      ].map((item: any) => {
        return <Route key={item} path={item} element={<Redirect url="/cattle/breed/murrah" />} />;
      })}
    </>
  );

  // Translation hook
  const { i18n } = useTranslation();
  // React Router location hook
  const loc = useLocation();

  useEffect(() => {
    const lang = loc.pathname.split(/\/([a-z]{2})(?![^\/])/gm)[1]; //eslint-disable-line
    const i = Object.keys(translations).findIndex((f) => f === lang);
    // if language different than known language
    if (i !== -1 && i18n.language !== lang) {
      // change language
      i18n.changeLanguage(lang);
      localStorage.setItem('language', lang);
    }
    if (localStorage.getItem('i18nextLng')) {
      localStorage.removeItem('i18nextLng');
    }
  }, [loc, i18n]);

  function renderLocalizedRoutes() {
    return Object.keys(translations).map((lang_code: string, id) => {
      return (
          // Generate url by language. Ex: test.com/en, test.com/en
          <Route key={id} path={lang_code}>
            {innerRoutes}
          </Route>
      );
    });
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {innerRoutes}
        {renderLocalizedRoutes()}
        <Route
          path="feed#aaharFEED_COMBO"
          element={<Redirect url="/feed/combo_offer" />}
        />
        <Route
          path="feed#aaharKHAL"
          element={<Redirect url="/feed/khal" />}
        />
        <Route
          path="feed#aaharGOLI_KHAL"
          element={<Redirect url="/feed/goli_khal" />}
        />
        <Route
          path="feed#aaharSILAGE"
          element={<Redirect url="/feed/silage" />}
        />
        <Route
          path="feed#aaharMINERAL_MIXTURE"
          element={<Redirect url="/feed/mineral_mix" />}
        />
        <Route
          path="feed#aaharTRADITIONAL_FEED"
          element={<Redirect url="/feed/traditional_khal" />}
        />
      </Route>
    </Routes>
  );
}

export default RoutesByLanguage;
