import STAR from '../../../assets/star_icon.svg';
import DOWNLOAD from '../../../assets/download_icon.svg';
import Button from '../../../components/Button/Button';
import TRUCK from '../../../assets/truck_icon.svg';
import THUMBSUP from '../../../assets/thumbsup.svg';
import DOCUMENT from '../../../assets/document.svg';
import { useTranslation } from 'react-i18next';

let CardColor = {
  GREEN: 'rgba(44, 172, 76, 0.2)',
  YELLOW: 'rgba(248, 195, 70, 0.2)',
  BLUE: 'rgba(3, 78, 162, 0.2)',
  RED: 'rgba(176, 0, 32, 0.2)',
};

type Props = {
  data: {
    deliveredBaffaloCount: string;

    deliveredFeedCount: string;

    qcCount: string;

    rating: string;

    totalDownloads: string;
  };
};

function WhatWeOffer({ data }: Props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="weoffer__container">
        <div className="weoffer__col1">
          <div className="weoffer__subtitle subheading green">
            {t('we_offer_sub')}
          </div>
          <div className="weoffer__heading heading">{t('we_offer_title')}</div>
          <div className="weoffer__cards-mobile">
            <div className="weoffer__card">
              <div
                className="weoffer__card--title hindi"
                style={{
                  backgroundColor: CardColor.GREEN,
                }}
              >
                {t('we_offer_one_title')}
              </div>
              <div className="weoffer__card--description">
                <img src={TRUCK} alt="" className="weoffer__card--icon" />
                <p className="weoffer__card--text hindi">
                  {t('we_offer_one_desc')}
                </p>
              </div>
            </div>
            <div className="weoffer__card">
              <div
                className="weoffer__card--title hindi"
                style={{
                  backgroundColor: CardColor.YELLOW,
                }}
              >
                {t('we_offer_two_title')}
              </div>
              <div className="weoffer__card--description">
                <img src={THUMBSUP} alt="" className="weoffer__card--icon" />
                <p className="weoffer__card--text hindi">
                  {t('we_offer_two_desc')}
                </p>
              </div>
            </div>
          </div>
          <div className="weoffer__stats">
            <div className="weoffer__stat">
              <img src={STAR} alt="" className="weoffer__stat--icon" />
              <div className="weoffer__stat--text">
                <p className="weoffer__stat--no">{data.rating}+</p>
                <p className="weoffer__stat--what">{t('rating_on_google_playStore')}</p>
              </div>
            </div>

            <div className="weoffer__stat">
              <img src={DOWNLOAD} alt="" className="weoffer__stat--icon" />
              <div className="weoffer__stat--text">
                <p className="weoffer__stat--no">{data.totalDownloads}+</p>
                <p className="weoffer__stat--what">{t('downloads')}</p>
              </div>
            </div>
          </div>
          <div className="weoffer__button" >
            {localStorage.getItem('i18nextLng') === 'en' ? (
              <Button primary text="DOWNLOAD APP & GET ₹100" playStore />
            ) : (
              <Button
                primary
                text={t('download_mob')}
                playStore = {true}
                to = "https://play.google.com/store/search?q=merapashu360&c=apps"
                className='button support__cta--1 weOfferbtn'
                
              />
            )}
          </div>
        </div>
        <div className="weoffer__col2">
          <div className="weoffer__col2--1">
            {/* <OfferCard titleBg={CardColor.GREEN} icon={TRUCK} text="" /> */}
            <div className="weoffer__card">
              <div
                className="weoffer__card--title hindi"
                style={{
                  backgroundColor: CardColor.GREEN,
                }}
              >
                {t('we_offer_one_title')}
              </div>
              <div className="weoffer__card--description">
                <img src={TRUCK} alt="" className="weoffer__card--icon" />
                <p className="weoffer__card--text hindi">
                  {t('we_offer_one_desc')}
                </p>
              </div>
            </div>
            <div className="weoffer__card">
              <div
                className="weoffer__card--title hindi"
                style={{
                  backgroundColor: CardColor.YELLOW,
                }}
              >
                {t('we_offer_two_title')}
              </div>
              <div className="weoffer__card--description">
                <img src={THUMBSUP} alt="" className="weoffer__card--icon" />
                <p className="weoffer__card--text hindi">
                  {t('we_offer_two_desc')}
                </p>
              </div>
            </div>
            {/* <OfferCard titleBg={CardColor.YELLOW} icon={THUMBSUP} text="" /> */}
          </div>

          <div className="weoffer__col2--2">
            <div className="weoffer__card">
              <div
                className="weoffer__card--title hindi"
                style={{
                  backgroundColor: CardColor.BLUE,
                }}
              >
                {t('we_offer_three_title')}
              </div>
              <div className="weoffer__card--description">
                <img src={DOCUMENT} alt="" className="weoffer__card--icon" />
                <p className="weoffer__card--text hindi">
                  {t('we_offer_three_desc')}
                </p>
              </div>
            </div>
            <div className="weoffer__card">
              <div
                className="weoffer__card--title hindi"
                style={{
                  backgroundColor: CardColor.RED,
                }}
              >
                {t('we_offer_four_title')}
              </div>
              <div className="weoffer__card--description">
                <img src={TRUCK} alt="" className="weoffer__card--icon" />
                <p className="weoffer__card--text hindi">
                  {t('we_offer_four_desc')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhatWeOffer;
